<script setup lang="ts">
import Logo from '~/domains/common/components/Logo.vue'
import LanguageSwitch from './LanguageSwitch.vue'

const localePath = useLocalePath()
const runtimeConfig = useRuntimeConfig()
</script>

<template>
  <footer class="w-full border-t border-gray-300 dark:border-gray-700">
    <div
      class="mx-auto flex max-w-4xl flex-col items-center gap-8 p-8 text-center"
    >
      <div
        class="flex flex-col items-center gap-2 text-sm text-gray-600 dark:text-gray-400"
      >
        <NuxtLink :to="localePath('/')" class="mb-4 text-xl font-bold">
          <Logo class="w-24" />
          <span class="sr-only">foodlista.it</span>
        </NuxtLink>
        <NuxtLink :to="localePath(`/privacy`)">
          {{ $t("common.footer.privacy") }}
        </NuxtLink>
        <NuxtLink :to="localePath(`/terms`)">
          {{ $t("common.footer.terms") }}
        </NuxtLink>
        <NuxtLink :to="runtimeConfig.public.publicWebBaseUrl" target="_blank">
          {{ $t("common.footer.explore") }}
        </NuxtLink>
      </div>
      <LanguageSwitch />
      <p class="text-sm text-gray-700 dark:text-gray-400">
        <i18n-t keypath="common.footer.legal" scope="global">
          <template #link>
            <NuxtLink to="https://vlkstudio.com" class="underline">
              VLK Studio
            </NuxtLink>
          </template>
        </i18n-t>
      </p>
    </div>
  </footer>
</template>
