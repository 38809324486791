<script setup lang="ts">
import type { SupportedLocale } from '@foodlista/shared/i18n/locales/types'
import { LOCALES } from '@foodlista/shared/i18n/locales/constants'

const switchLocalePath = useSwitchLocalePath()
const { locale } = useI18n()

const dropdownItems = computed(() =>
  Object.entries(LOCALES)
    .filter(([_, locale]) => locale.ui)
    .map(([key, locale]) => ({
      label: locale.name,
      href: switchLocalePath(key),
    })),
)

const selectedLocale = computed(
  () => LOCALES[locale.value as SupportedLocale],
)
</script>

<template>
  <UDropdown :items="[dropdownItems]">
    <UButton
      color="gray"
      size="sm"
      variant="link"
      icon="i-tabler-language"
      :padded="false"
    >
      {{ selectedLocale.name }}
    </UButton>
  </UDropdown>
</template>
